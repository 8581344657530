import React, { useCallback } from "react";
import { Button, TextField } from "@material-ui/core";
import { setCurrentLocation } from "../../state/ducks/map/actions";
import { DEFAULTCENTER } from "../containers/map";

type SearchPlaceProps = {
  onChangePlace: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style: {};
  label: string;
  searchPlace: string;
  isLoading: boolean;
  dragged: boolean;
  zoomed: boolean;
  setIsGeocodeLoding: React.Dispatch<React.SetStateAction<boolean>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  setDragged: React.Dispatch<React.SetStateAction<boolean>>;
  setZoomed: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: any;
};
const SearchPlace: React.FC<SearchPlaceProps> = ({
  onChangePlace,
  style,
  label,
  searchPlace,
  isLoading,
  dragged,
  zoomed,
  setIsGeocodeLoding,
  setZoom,
  setDragged,
  setZoomed,
  dispatch,
}) => {
  const onClickGeocode = useCallback(
    async (searchPlace) => {
      try {
        if (searchPlace === "") return;
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        await service.findPlaceFromQuery(
          {
            query: searchPlace,
            fields: ["name", "formatted_address", "geometry", "place_id"],
          },
          async (
            results: google.maps.places.PlaceResult[] | null,
            status: keyof typeof window.google.maps.places.PlacesServiceStatus
          ) => {
            setIsGeocodeLoding(true);
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              !!results &&
              !!results[0].geometry &&
              !!results[0].geometry.location
            ) {
              const location = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };
              // 一度リセットしてから移動を行う
              if (dragged || zoomed) {
                // 画面が一度でも移動されていればリセット
                await setCurrentLocation(dispatch, DEFAULTCENTER);
              }
              await setCurrentLocation(dispatch, location);
              console.dir(location);
            }
            setTimeout(() => {
              setIsGeocodeLoding((isLoading) => false);
            }, 1000);
          }
        );
      } catch (e) {
        console.dir(e);
      }

      // 一度リセットしてからズームを行う
      setZoom(20);
      setDragged(false);
      setZoomed(false);
    },
    [
      dragged,
      dispatch,
      zoomed,
      setDragged,
      setZoomed,
      setZoom,
      setIsGeocodeLoding,
    ]
  );
  return (
    <div
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextField
        size="small"
        disabled={isLoading}
        type="text"
        label={label}
        onChange={onChangePlace}
        value={searchPlace}
        inputProps={{ style: { width: 130 } }}
      />
      <Button
        size="small"
        disabled={isLoading}
        variant="text"
        onClick={(e) => onClickGeocode(searchPlace)}
      >
        検索
      </Button>
    </div>
  );
};

export default SearchPlace;
