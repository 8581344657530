import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Map from './map'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Map} />
        <Route path='/map' component={Map} />
      </Switch>
      {/* <Link to='/'>Back To Top</Link> */}
    </BrowserRouter>
  );
};

export default App;
