import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"

const defaultState = {
  mapsData: [],
  markerArray: [],
  searchPlace: "",
  currentLocation: {},
  filteringWord: "",
  loginStatus: "",
  loginUserInfo: {},
  clusters: [],
  myRank: 0,
  usersNum: 0,
  myStampedCnt: 0,
}
const map = (state = defaultState, action) => {
  switch (action.type) {
    case "LIST_MAPS":
      return {
        ...state,
        allMaps: action.payload.mapsData,
        mapsData: action.payload.mapsData,
      };
    case "SET_SEARCH_PLACE":
      return {
        ...state,
        searchPlace: action.payload.searchPlace,
      };
    case "SET_CURRENT_LOCATION":
      return {
        ...state,
        currentLocation: action.payload.currentLocation,
      };
    case "SET_STAMP":
      return {
        ...state,
        mapsData: [ ...action.payload.mapsData ],
      };
    case "SET_FILTERING_MAPS":
      return {
        ...state,
        mapsData: [ ...action.payload.mapsData ],
        filteringWord: action.payload.filteringWord,
      };
    case "LOGIN_STATUS":
      return {
        ...state,
        loginStatus: action.payload.loginStatus,
        loginUserInfo: action.payload.loginUserInfo,
      };
    case "SET_CLUSTERS":
      return {
        ...state,
        clusters: action.payload.clusters,
      };
    case "SET_RANK":
      return {
        ...state,
        myRank: action.payload.myRank,
        usersNum: action.payload.usersNum,
        myStampedCnt: action.payload.myStampedCnt,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  map: map,
  form: formReducer,
});

export default reducer;