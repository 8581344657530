type MarkerProps = {
  url: string;
};
const Marker = ({ url }: MarkerProps) => (
  <img
    src={url}
    width={59}
    height={59}
    style={{ position: "relative", bottom: 35, left: -45 }}
    alt="marker"
  />
);

export default Marker;
