import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { SiNiconico } from "react-icons/si";
import { AiFillYoutube } from "react-icons/ai";
import { EditingMarkerType } from "../containers/map";

type PhotoDropDialogProps = {
  editingMarker: EditingMarkerType | null;
  isAdmin: boolean;
  onClose: () => void;
  open: boolean;
  isLoading: boolean;
  onDelete: (map: any) => void;
  onClickAddPhoto: (
    imgUrl: string,
    name: string,
    explanation: string,
    title: string,
    oldName: string
  ) => void;
};
type FileWidthPreview = File & {
  preview: string;
};
export const PhotoDropDialog: React.FC<PhotoDropDialogProps> = ({
  editingMarker,
  isAdmin,
  onClose,
  open,
  isLoading,
  onDelete,
  onClickAddPhoto,
}) => {
  const [files, setFiles] = useState<FileWidthPreview[]>([]);
  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [explanation, setExplanation] = useState<string>("");
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);

  // ダイアログの開閉のたびに初期化
  useEffect(() => {
    setIsCreateUser(isAdmin);
    if (!editingMarker) {
      //新規
      setFiles([]);
      setName("");
      setTitle("");
      setExplanation("");
    } else {
      //編集
      setName(editingMarker.name);
      setTitle(editingMarker.title);
      setExplanation(editingMarker.explanation);
    }
  }, [open, editingMarker, isAdmin]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const imgStyles = {
    maxHeight: "45vh",
    width: "100%",
    borderRadius: 15,
    cursor: isCreateUser ? "pointer" : "auto",
  };
  let thumb = null;
  if (0 < files.length) {
    // 新規or編集→画像選択後
    thumb = files.map((file) => (
      <React.Fragment>
        <img style={{ ...imgStyles }} src={file.preview} alt="img" />
      </React.Fragment>
    ))[0];
  } else {
    // 新規or編集
    thumb = !!editingMarker ? (
      <React.Fragment>
        <img
          style={{ ...imgStyles }}
          src={editingMarker && editingMarker.url}
          alt="img"
        />
      </React.Fragment>
    ) : null;
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={onClose}>
      <DialogTitle style={{ padding: "16px 24px 8px 24px" }}>
        {isCreateUser && (
          <React.Fragment>
            <div style={{ fontSize: 16 }}>聖地を登録</div>
            <div style={{ fontSize: 10, color: "#999999" }}>
              ※画像をタップするともう一度画像を選び直せます
            </div>
          </React.Fragment>
        )}
        {/* {!isCreateUser &&
          <div style={{ fontSize: 16 }}>登録したひと：</div>
          } */}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              {isCreateUser && <input {...getInputProps()} />}
              {!!thumb && <React.Fragment>{thumb}</React.Fragment>}
              {!thumb && <p>タップで写真を追加</p>}
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {isCreateUser && (
              <div
                style={{
                  padding: "10px 14px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  type="text"
                  label="聖地名称"
                  onChange={(e) => setName(e.target.value)}
                  value={name || ""}
                  style={{ padding: 8 }}
                />
                <TextField
                  type="text"
                  label="枠名など"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title || ""}
                  style={{ padding: 8 }}
                />
                <TextField
                  type="text"
                  label="聖地についての説明"
                  multiline
                  minRows={4}
                  maxRows={8}
                  onChange={(e) => setExplanation(e.target.value)}
                  value={explanation || ""}
                  style={{ padding: 8 }}
                />
              </div>
            )}
            {!isCreateUser && (
              <>
                <div style={{ padding: "10px 14px" }}>
                  <div style={{ marginBottom: 8 }}>
                    <div
                      style={{
                        fontSize: 10,
                        color: "salmon",
                        padding: "0 0 4px 0",
                      }}
                    >
                      マップ検索
                    </div>
                    <div
                      style={{
                        marginBottom: 16,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{ width: "10px" }} />
                      <Button
                        variant="contained"
                        size="small"
                        style={{ fontSize: 12, background: "darkcyan" }}
                      >
                        <a
                          style={{ color: "#FFF", textDecoration: "none" }}
                          href={`https://www.google.com/maps/search/?api=1&query=${
                            editingMarker && editingMarker.lat
                          },${editingMarker && editingMarker.lng}`}
                        >
                          GoogleMap
                        </a>
                      </Button>
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <div style={{ fontSize: 10, color: "salmon" }}>
                      聖地名称
                    </div>
                    <div style={{ fontSize: 14, padding: "6px 8px" }}>
                      {name}
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <div style={{ fontSize: 10, color: "salmon" }}>枠名</div>
                    <div style={{ padding: "0 8px" }}>
                      {title.split(" OR ").map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          {/* youtube */}
                          <a
                            style={{
                              color: "#FFF",
                              textDecoration: "none",
                              margin: "5px 10px 0 0",
                            }}
                            href={`https://www.youtube.com/results?search_query=七原くん ${item}`}
                          >
                            <AiFillYoutube color="crimson" fontSize="20px" />
                          </a>
                          {/* niconico */}
                          <a
                            style={{
                              color: "#FFF",
                              textDecoration: "none",
                              margin: "3px 10px 0 0",
                            }}
                            href={`https://www.nicovideo.jp/search/七原くん ${item}?track=seiga_illust_keyword`}
                          >
                            <SiNiconico color="slategray" fontSize="20px" />
                          </a>
                          <div style={{ fontSize: 14 }}>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div style={{ fontSize: 10, color: "salmon" }}>
                      聖地についての説明
                    </div>
                    {!!explanation && (
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          fontSize: 14,
                          padding: "6px 8px",
                        }}
                      >
                        {explanation}
                      </div>
                    )}
                    {!explanation && (
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          fontSize: 12,
                          padding: "6px 8px",
                          color: "#999999",
                        }}
                      >
                        （説明が書かれていないようです）
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions
        style={
          !editingMarker
            ? {}
            : {
                display: "flex",
                alignItems: "center",
                justifyContent: !isCreateUser ? "flex-end" : "space-between",
              }
        }
      >
        {!!editingMarker && isCreateUser && (
          <Button
            disabled={isLoading || !isCreateUser}
            onClick={() => onDelete(editingMarker)}
          >
            削除
          </Button>
        )}
        <div>
          <Button onClick={() => onClose()}>キャンセル</Button>
          {isCreateUser && (
            <Button
              disabled={
                isLoading ||
                !name ||
                !title ||
                (!editingMarker && !files[0]) ||
                !isCreateUser
              }
              onClick={(e) =>
                onClickAddPhoto(
                  !!files[0] ? files[0].preview : editingMarker?.url || '',
                  name,
                  explanation,
                  title,
                  editingMarker?.name || ''
                )
              }
            >
              {!editingMarker ? "登録" : "更新"}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PhotoDropDialog;
