import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCC62Bvln71F53GQQD6qw517IlqZTMMH4E",
  authDomain: "stamprallyapp.firebaseapp.com",
  projectId: "stamprallyapp",
  storageBucket: "stamprallyapp.appspot.com",
  messagingSenderId: "111139760629",
  appId: "1:111139760629:web:2049c04419a4847b1898bc"
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);