import GoogleMapReact from "google-map-react";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Snackbar, TextField } from "@material-ui/core";
import PhotoDropDialog from "../components/photoDropDialog";
import {
  listMaps,
  registMaps,
  setSearchPlace,
  setFilteringMaps,
  deleteMap,
  loginOrLogoutWithTwitter,
  checkAuth,
  LOGIN_STATUS,
} from "../../state/ducks/map/actions";
import SearchPlace from "../components/searchPlace";
import googleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import Cluster from "../components/cluster";
import Place from "../components/place";
import Marker from "../components/marker";

export const USER_ID = "9nRYnyugs0AVzOdBhS3o";

//アプリ初期表示時に画面に日本が収まるような位置
export const DEFAULTCENTER = {
  lat: 39.237363871491354,
  lng: 137.33612457223325,
};

const BOUNDS_DEFAULT = [
  122.87, // 最小経度 (西)
  24.84, // 最小緯度 (南)
  153.01, // 最大経度 (東)
  45.55, // 最大緯度 (北)
];

export type EditingMarkerType = {
  id: string;
  lat: number;
  lng: number;
  name: string;
  title: string;
  explanation: string;
  url: string;
};
const Map = (props: any) => {
  const [newMarker, setNewMarker] = useState<googleMapReact.Coords | null>(
    null
  );
  const [editingMarker, setEditingMarker] = useState<EditingMarkerType | null>(
    null
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [zoom, setZoom] = useState(5);
  const [dragged, setDragged] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [currentBounds, setCurrentBounds] = useState<number[]>([]);
  const [isLoading, setIsLoding] = useState(false);
  const [isGeocodeLoading, setIsGeocodeLoding] = useState(false);
  const [filteringWord, setFilteringWord] = useState("");

  useEffect(() => {
    const getList = async () => {
      await listMaps(props.dispatch, true);
      await checkAuth(props.dispatch);
    };
    getList();
  }, [props.dispatch]);

  const onClickMap = useCallback(
    (value: googleMapReact.ClickEventValue) => {
      setNewMarker({ lat: value.lat, lng: value.lng });
    },
    [setNewMarker]
  );

  const onClosePhotoDialog = useCallback(() => {
    setNewMarker(null);
    setEditingMarker(null);
  }, [setNewMarker, setEditingMarker]);

  //追加（更新）
  const onClickAddPhoto = async (
    imgUrl: string,
    name: string,
    explanation: string,
    title: string,
    oldName: string
  ) => {
    const mapsDataForClusters: any = [...props.mapsData];
    const isNew = !editingMarker;
    const lat = !isNew ? editingMarker.lat : newMarker && newMarker.lat;
    const lng = !isNew ? editingMarker.lng : newMarker && newMarker.lng;
    let mapData = (await !isNew)
      ? mapsDataForClusters.find(
          (data: any) => data.id === (editingMarker && editingMarker.id)
        )
      : null;
    if (!isNew) {
      mapData.name = name;
      mapData.title = title;
      mapData.explanation = explanation;
      mapData.lat = lat;
      mapData.lng = lng;
      mapData.stampedUids = !!mapData ? mapData.stampedUids : [];
      mapData.createUserId = props.loginUserInfo.id;
    } else {
      mapData = {};
      mapData.id = "";
      mapData.name = name;
      mapData.title = title;
      mapData.explanation = explanation;
      mapData.url = imgUrl;
      mapData.lat = lat;
      mapData.lng = lng;
      mapData.stampedUids = [];
      mapData.createUserId = props.loginUserInfo.id;
      mapsDataForClusters.push(mapData);
    }

    const oldData = {
      id: mapData && mapData.id,
      name: oldName,
      url: mapData && mapData.url,
      lat: lat,
      lng: lng,
    };
    setIsLoding(true);
    // const getItems = () as {
    //   id: string;
    //   url?: undefined;
    // };
    // if (isNew) {
    //   mapData.id = getItems.id;
    // } else {
    //   mapData.url = getItems.url;
    // }
    await registMaps(isNew, mapData, oldData, imgUrl, props.loginUserInfo);
    await listMaps(props.dispatch);
    //await getClustersはonChangePlaceFilter内部でやっている
    onChangePlaceFilter(null, true, mapsDataForClusters);
    setIsLoding(false);
    setNewMarker(null);
    setEditingMarker(null);
  };

  //削除
  const onDelete = async (map: any) => {
    setIsLoding(true);
    if (!map) return;
    await deleteMap(props.dispatch, map);
    await listMaps(props.dispatch);
    const mapsDataForClusters = props.mapsData.filter(
      (data: any) => data.id !== map.id
    );
    console.dir(mapsDataForClusters);
    //await getClustersはonChangePlaceFilter内部でやっている
    onChangePlaceFilter(null, true, mapsDataForClusters);
    // await getClusters(props.dispatch, mapsDataForClusters, map.id, props.loginUserInfo);
    setNewMarker(null);
    setEditingMarker(null);
    setIsLoding((isLoading) => false);
  };

  //スタンプ
  // const onClickStamp = async (e, data, id) => {
  //   if (!props.loginStatus) return;
  //   const editingMapsData = [...props.mapsData];
  //   if (
  //     props.clusters.find((data) => data.properties.id === id).properties
  //       .isStamped
  //   )
  //     return;
  //   setIsLoding((isLoading) => true);
  //   await navigator.geolocation.getCurrentPosition(async (position) => {
  //     const loc = {
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     };
  //     //ヒュベニの公式
  //     //35.817769672704614, 139.72852624797972自宅
  //     const lat1 = (loc.lat * Math.PI) / 180;
  //     const lng1 = (loc.lng * Math.PI) / 180;
  //     const lat2 = (data.lat * Math.PI) / 180;
  //     const lng2 = (data.lng * Math.PI) / 180;

  //     // 緯度差
  //     const latDiff = lat1 - lat2;
  //     // 経度差算
  //     const lngDiff = lng1 - lng2;
  //     // 平均緯度
  //     const latAvg = (lat1 + lat2) / 2.0;
  //     // 赤道半径
  //     const a = 6378137.0;
  //     // 第一離心率^2
  //     const e2 = 0.00669438002301188;
  //     // 赤道上の子午線曲率半径
  //     const a1e2 = 6335439.32708317;

  //     const sinLat = Math.sin(latAvg);
  //     const W2 = 1.0 - e2 * (sinLat * sinLat);

  //     // 子午線曲率半径M
  //     const M = a1e2 / (Math.sqrt(W2) * W2);
  //     // 卯酉線曲率半径
  //     const N = a / Math.sqrt(W2);

  //     const t1 = M * latDiff;
  //     const t2 = N * Math.cos(latAvg) * lngDiff;
  //     const result = Math.sqrt(t1 * t1 + t2 * t2);

  //     //TODO:
  //     if (50 < result) {
  //       setAlertMessage("目的地の50m以内に入ってください。");
  //       setIsLoding((isLoading) => false);
  //       return;
  //     }
  //     //50m以内ならスタンプOK
  //     await setStamp(props.dispatch, editingMapsData, id, props.loginUserInfo);
  //     await listMaps(props.dispatch);
  //     await getClusters(
  //       props.dispatch,
  //       props.mapsData,
  //       id,
  //       props.loginUserInfo
  //     );
  //     await getRanking(props.dispatch);
  //     setAlertMessage("スタンプを押しました。");
  //     setIsLoding((isLoading) => false);
  //   });
  //   setIsLoding((isLoading) => false);
  // };

  const onClickLogin = async () => {
    setIsLoding(true);
    const success = await loginOrLogoutWithTwitter(
      props.dispatch,
      true,
      props.mapsData
    );
    setAlertMessage(
      success ? "ログインしました。" : "ログインが完了しませんでした。"
    );
    setIsLoding((isLoading) => false);
  };
  const onClickLogout = async () => {
    setIsLoding(true);
    const success = await loginOrLogoutWithTwitter(props.dispatch, false);
    setAlertMessage(
      success ? "ログアウトしました。" : "ログアウトが完了しませんでした。"
    );
    setIsLoding(false);
  };

  //フィルタリング
  const onChangePlaceFilter = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    isNotClick = false,
    currentMaps = null
  ) => {
    const eTargetValue = e ? e.target.value : "";
    const filteringWord = isNotClick ? props.filteringWord : eTargetValue;
    setFilteringWord(filteringWord);
    let maps = !!currentMaps ? [...currentMaps] : [...props.allMaps];
    maps = maps.filter((data) => {
      if (
        data.name.indexOf(filteringWord) !== -1 ||
        data.title.indexOf(filteringWord) !== -1 ||
        data.explanation.indexOf(filteringWord) !== -1 ||
        !filteringWord
      ) {
        return true;
      }
      return false;
    });
    await setFilteringMaps(
      props.dispatch,
      maps,
      filteringWord,
      props.loginUserInfo
    );
  };

  const onChangePlace = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = e.target.value;
    await setSearchPlace(props.dispatch, searchWord);
  };

  const onDragEnd = () => {
    if (dragged || isLoading) return;
    setDragged(true);
  };

  const onZoomAnimationStart = () => {
    if (zoomed || isLoading) return;
    setZoomed(true);
  };

  const onChangeMap = (bounds: googleMapReact.Bounds, zoom: number) => {
    if (isLoading) return;
    setCurrentBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ]);
    setZoom(zoom);
  };

  //スクロールバーがでないようにするための調整
  let forNoScrollBar = "4px";
  if (window.innerHeight < 168) {
    forNoScrollBar = "70px";
  } else if (window.innerHeight < 236) {
    forNoScrollBar = "60px";
  } else if (window.innerHeight < 268) {
    forNoScrollBar = "50px";
  } else if (window.innerHeight < 336) {
    forNoScrollBar = "40px";
  } else if (window.innerHeight < 436) {
    forNoScrollBar = "30px";
  } else if (window.innerHeight < 537) {
    forNoScrollBar = "20px";
  } else if (window.innerHeight < 636) {
    forNoScrollBar = "10px";
  }

  const { clusters } = useSupercluster({
    points: props.clusters,
    bounds: currentBounds.length ? currentBounds : BOUNDS_DEFAULT,
    zoom: zoom,
    options: { radius: 50, maxZoom: 20 },
  });

  const isAdminUser = useMemo(() => {
    return props.loginUserInfo.id !== USER_ID;
  }, [props.loginUserInfo.id]);

  const isNotMapClickable = useMemo(() => {
    return (
      isAdminUser ||
      isLoading ||
      !props.loginStatus ||
      props.loginStatus === LOGIN_STATUS.logout
    );
  }, [isAdminUser, isLoading, props.loginStatus]);

  const onClickMarker = useCallback(
    (data, id) => {
      setEditingMarker({ ...data, id: id });
    },
    [setEditingMarker]
  );

  return (
    <div style={{ overflow: "hidden" }}>
      {/* ロゴ */}
      <div style={{ position: "absolute", zIndex: 100, top: 4, right: "5px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* 地名検索 */}
          <SearchPlace
            style={{ backgroundColor: "#FFF" }}
            label="地名検索"
            onChangePlace={onChangePlace}
            searchPlace={props.searchPlace}
            isLoading={isGeocodeLoading}
            dragged={dragged}
            zoomed={zoomed}
            setIsGeocodeLoding={setIsGeocodeLoding}
            setZoom={setZoom}
            setDragged={setDragged}
            setZoomed={setZoomed}
            dispatch={props.dispatch}
          />
          <div style={{ height: "4px" }}></div>
          <a
            style={{ fontSize: 12, color: "white", textDecoration: "none" }}
            href="https://twitter.com/kakikukekoichi"
          >
            <Button size="small" variant="contained" color="primary">
              @kakikukekoichi(開発者)
            </Button>
          </a>
        </div>
      </div>

      <img
        src="image/logo.png"
        alt="holagoa"
        style={{
          position: "absolute",
          left: 5,
          top: 4,
          zIndex: 100,
          borderRadius: 4,
          border: "3px solid chocolate",
        }}
      />

      <PhotoDropDialog
        open={!!newMarker || !!editingMarker}
        editingMarker={editingMarker}
        onClose={onClosePhotoDialog}
        onClickAddPhoto={onClickAddPhoto}
        onDelete={onDelete}
        isLoading={isLoading}
        isAdmin={props.loginUserInfo.id === "9nRYnyugs0AVzOdBhS3o"}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        onClose={() => {
          setAlertMessage("");
        }}
        autoHideDuration={6000}
        message={alertMessage}
      >
        <div
          style={{
            color: "snow",
            backgroundColor: "#323232",
            borderRadius: 4,
            width: "100%",
            textAlign: "center",
            padding: "6px 20px",
            fontSize: 14,
          }}
        >
          {alertMessage}
        </div>
      </Snackbar>

      <div
        style={{
          maxHeight: "90vh",
          height: `calc(90vh - ${forNoScrollBar})`,
          width: "100%",
        }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps, ref }) =>
            () => {}}
          bootstrapURLKeys={{
            key: "AIzaSyDaTBsX8QJk5lQXCyqZXduo2mtEw6hkVNs",
            libraries: ["places"],
          }}
          defaultCenter={DEFAULTCENTER}
          center={
            props.currentLocation.lat ? props.currentLocation : DEFAULTCENTER
          }
          zoom={zoom || 5}
          onClick={isNotMapClickable ? () => {} : (value) => onClickMap(value)}
          onChange={({
            center,
            zoom,
            bounds,
            marginBounds,
            size,
          }: GoogleMapReact.ChangeEventValue) => onChangeMap(bounds, zoom)}
          onDragEnd={() => onDragEnd()}
          onZoomAnimationStart={() => onZoomAnimationStart()}
        >
          {clusters.map((cluster, i) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster } = cluster.properties;
            if (isCluster) {
              return (
                <Cluster
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  cluster={cluster}
                />
              );
            }
            return (
              <Place
                key={i}
                lat={cluster.properties.lat}
                lng={cluster.properties.lng}
                isAdminUser={isAdminUser}
                name={cluster.properties.name}
                url={cluster.properties.url}
                onClickMarker={() =>
                  onClickMarker(cluster.properties, cluster.properties.id)
                }
              />
            );
          })}
          {!!props.currentLocation.lat && !zoomed && (
            <Marker url="/image/マーカー.png" />
          )}
        </GoogleMapReact>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-end",
          flexDirection: "column",
          height: "10vh",
          maxHeight: "10vh",
          minHeight: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 16,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* 聖地検索 */}
              <div style={{ fontSize: 10, color: "salmon" }}>聖地検索</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <TextField
                  type="text"
                  onChange={onChangePlaceFilter}
                  value={filteringWord || ""}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                overflowY: "auto",
                marginRight: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Button
                    disabled={isLoading || !props.loginStatus}
                    style={{
                      backgroundColor: "#FFF",
                      margin: 0,
                      maxHeight: 37,
                      height: 37,
                    }}
                    onClick={
                      props.loginStatus === LOGIN_STATUS.login
                        ? onClickLogout
                        : onClickLogin
                    }
                  >
                    {props.loginStatus === LOGIN_STATUS.login
                      ? "LOGOUT"
                      : "LOGIN"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type MapRouterProps = RouteComponentProps & {
  mapsData: any[];
  clusters: any[];
  allMaps: any[];
  searchPlace: string;
  filteringWord: string;
  currentLocation: any[];
  loginStatus: keyof typeof LOGIN_STATUS;
  loginUserInfo: any;
  history: RouteComponentProps["history"] | null;
  location: RouteComponentProps["location"] | null;
  match: RouteComponentProps["match"] | null;
};
const mapStateToProps = (state: any): MapRouterProps => {
  return {
    mapsData:
      (state.mapState && state.mapState.map && state.mapState.map.mapsData) ||
      [],
    clusters:
      (state.mapState && state.mapState.map && state.mapState.map.clusters) ||
      [],
    allMaps:
      (state.mapState && state.mapState.map && state.mapState.map.allMaps) ||
      [],
    searchPlace:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.searchPlace) ||
      "",
    filteringWord:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.filteringWord) ||
      "",
    currentLocation:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.currentLocation) ||
      {},
    loginStatus:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.loginStatus) ||
      "",
    loginUserInfo:
      (state.mapState &&
        state.mapState.map &&
        state.mapState.map.loginUserInfo) ||
      {},
    history: state.history,
    location: state.location,
    match: state.match,
  };
};

export default connect(mapStateToProps)(withRouter(Map));
