import { collection, addDoc, doc, updateDoc, getDoc, getDocs, deleteDoc, serverTimestamp, query, where } from "firebase/firestore";
import { db } from "../../../firebase"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { getAuth, signInWithPopup, onAuthStateChanged, GoogleAuthProvider } from "firebase/auth";

export const LOGIN_STATUS = { login: "LOGIN", logout: "LOGOUT" };

export const loginOrLogoutWithTwitter = async (dispatch, isLogin, mapsData) => {
  try {
    let success = false;
    const auth = getAuth();
    // Googleログインを呼び出すためのボタンを設定します
    var provider = new GoogleAuthProvider();
    if (!isLogin) {
      auth.signOut();
      dispatch(setloginStatusAction(LOGIN_STATUS.logout, null));
      return true;
    }
    success = await signInWithPopup(auth, provider)
      .then(async (result) => {
        // twitterで取得した名前とID
        const displayName = result.user && result.user.displayName;
        const uid = result.user && result.user.uid;

        const usersRef = collection(db, "users");
        const usersSnap = await getDocs(usersRef);

        // 登録済みのユーザー情報
        const usersData = [];
        usersSnap.forEach(user => {
          if(user.exists()) {
            usersData.push({ ...user.data(), id: user.id });
          }
        });
        const userData = usersData.find(user => user.uid === uid);

        let userRef = null;
        if (!userData) {
          // 未登録
          userRef = await addDoc(usersRef, {
            displayName: displayName,
            uid: uid,
          });
        } else if (userData && (userData.name !== displayName)) {
          // 名前が変わっていれば更新
          userRef = doc(db, "users", userData.id);
          await updateDoc(userRef, {
            displayName: displayName,
          });
        }
        if (isLogin) {
          await getClusters(dispatch, mapsData, null, { displayName: displayName, uid: uid, id: userRef.id });
        }
        dispatch(setloginStatusAction(LOGIN_STATUS.login, { displayName: displayName, uid: uid, id: userRef.id }));
      }).catch((e) => {
        console.dir(e);
        //popupを閉じた際もエラーになるのでここでreturn falseはしない
      });
      console.dir(typeof success)
    return !!success;
  } catch (e) {
    console.dir(e);
    return false;
  }
}

export const getRanking = async (dispatch) => {
  try {
    const usersData = [];
    const usersRef = collection(db, "users");
    const usersSnap = await getDocs(usersRef);
    usersSnap.forEach(user => {
      if(user.exists()) {
        usersData.push({ ...user.data(), id: user.id });
      }
    });
    const auth = await getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const userData = usersData.find(user => user.uid === currentUser.uid);
    const myStampedCnt = !!userData.stampedCnt ? userData.stampedCnt : 0;
    const allUsersStampedCnt = usersData.map(user => !!user.stampedCnt ? user.stampedCnt : 0).sort((a, b) => b - a );
    const usersNum = usersData.length;
    const myRank = allUsersStampedCnt.indexOf(myStampedCnt)+1;
    dispatch(setRankAction(myRank, usersNum, myStampedCnt));
  } catch(e) {
    console.dir(e);
  }
}

export const checkAuth = async (dispatch) => {
  try {
    const auth = await getAuth();
    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = collection(db, "users");
        const q = query(userRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const userIds = [];
        querySnapshot.forEach(snap => {
          if(snap.exists()) {
            console.dir(snap)
            userIds.push(snap.id);
          }
        })
        dispatch(setloginStatusAction(LOGIN_STATUS.login, { displayName: user.displayName, uid: user.uid, id: userIds[0] || ""}));
      } else {
        dispatch(setloginStatusAction(LOGIN_STATUS.logout, null));
      }
    })
  } catch (e) {
    console.dir(e);
  }
}

export const listMaps = async (dispatch, isInitialize=false) => {
  try {
    const mapsRef = collection(db, "maps");
    const mapsSnap = await getDocs(mapsRef);
    const mapsData = [];
    mapsSnap.forEach(map => {
      if(map.exists()) {
        mapsData.push({ ...map.data(), id: map.id });
      }
    });

    const storage = getStorage();
    const newData = mapsData;
    await Promise.all(mapsData.map(async (data, i) => {
      await getDownloadURL(ref(storage, `${data.id}/${data.name}`))
        .then(async (url) => {
          const res = await fetch(url);
          const blob = await res.blob();
          newData[i].url = URL.createObjectURL(blob);
        })
        .catch((e) => {
          console.dir(e);
        });
    }));
    dispatch(listMapsAction(newData));

    if (isInitialize) {
      //isStampedはすべてfalse（ログインユーザーいないので）
      await getClusters(dispatch, newData, null, null);
    }
  } catch(e) {
    console.dir(e)
  }
}

export const registMaps = async (isNew, data, oldData, imgUrl, loginUserInfo=null) => {
  try {
    // 画像をBlobでアップロード
    const storage = getStorage();
    const res = await fetch(imgUrl);
    const blob = await res.blob();
    if (isNew) {
      //新規
      const mapsRef = collection(db, "maps");
      const mapRef = await addDoc(mapsRef, {
        name: data.name,
        title: data.title,
        explanation: data.explanation,
        stampedUids: [],
        stampedTimeStamp: null,
        lat: data.lat,
        lng: data.lng,
        createUserId: loginUserInfo.id,
        createTimeStamp: serverTimestamp(),
      });

      // 画像をBlobでアップロード
      const storageRef = ref(storage, `${mapRef.id}/${data.name}`);
      await uploadBytes(storageRef, blob).then((snapshot) => {
        console.log('Uploaded a blob or file!');
      });
      return true;
    } else {
      //更新
      const mapsRef = doc(db, "maps", data.id);
      await updateDoc(mapsRef, {
        name: data.name,
        title: data.title,
        explanation: data.explanation,
      });

      if (!!imgUrl || (data.name !== oldData.name)) {
        const desertRef = ref(storage, `${oldData.id}/${oldData.name}`);
        await deleteObject(desertRef).then(() => {
          console.dir("File deleted successfully");
        }).catch((e) => {
          console.dir(e);
        });

        const storageRef = ref(storage, `${data.id}/${data.name}`);
        await uploadBytes(storageRef, blob).then((snapshot) => {
          console.log('Uploaded a blob or file!');
        }).catch((e) => {
          console.dir(e);
        });
      }
      return true;
    }
  } catch(e) {
    console.dir(e)
    return false;
  }
}

export const deleteMap = async (dispatch, map) => {
  try {
    //データ削除
    const mapRef = doc(db, "maps", map.id);
    await deleteDoc(mapRef);

    //画像削除
    const storage = getStorage();
    const desertRef = ref(storage, `${map.id}/${map.name}`);
    await deleteObject(desertRef).then(() => {
      console.dir("File deleted successfully");
    }).catch((e) => {
      console.dir(e);
    });
  } catch(e) {
    console.dir(e);
  }
}

export const setSearchPlace = (dispatch, searchPlace) => {
  try {
    dispatch(setSearchPlaceAction(searchPlace));
  } catch(e) {
    console.dir(e);
  }
}

export const setCurrentLocation = (dispatch, currentLocation) => {
  try {
    dispatch(setCurrentLocationAction(currentLocation));
  } catch(e) {
    console.dir(e);
  }
}

export const setStamp = async (dispatch, editingMapsData, id, loginUserInfo) => {
  try {
    const editingMapData = editingMapsData.find(map => map.id === id);
    editingMapData.isStamped = true;
    editingMapData.stampedUids.push(loginUserInfo.uid);
    editingMapData.stampedTimeStamp = serverTimestamp();
    const mapRef = doc(db, "maps", id);
    await updateDoc(mapRef, {
      stampedUids: editingMapData.stampedUids,
      stampedTimeStamp: editingMapData.stampedTimeStamp,
    });
    const userRef = doc(db, "users", loginUserInfo.id);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) return;
    const userData = userSnap.data();
    await updateDoc(userRef, {
      stampedCnt: !!userData.stampedCnt ? userData.stampedCnt+1 : 1,
    });

    dispatch(setStampAction(editingMapsData));
  } catch(e) {
    console.dir(e);
  }
}

export const getClusters = (dispach, mapsData, stampedId, loginUserInfo) => {
  //stampedIdがあるとき→スタンプ押下時のみ
  const clusters = mapsData.map(data => {
    let isStamped = false;
    const loginUid = !!loginUserInfo ? loginUserInfo.uid : "";
    if ((data.stampedUids || []).includes(loginUid) && ((stampedId === data.id) || !!data.stampedTimeStamp)) {
        // || ((data.stampedUids.includes(loginUid)) && (!!data.stampedTimeStamp && (new Date().getDate() ===  data.stampedTimeStamp.toDate().getDate())))) {
      //ログインユーザーがスタンプを押している＆押した日が今日である場合
      //聖地巡礼モードで今スタンプを押した場合
      isStamped = true;
    } else {
      isStamped = false;
    }
    return {
      type: "Feature",
      properties: {
        cluster: false,
        id: data.id,
        name: data.name,
        title: data.title,
        url: data.url,
        isStamped: isStamped,
        explanation: data.explanation,
        lat: data.lat,
        lng: data.lng,
        createUserId: data.createUserId,
      },
      geometry: {
        type: "Point",
        coordinates: [data.lng, data.lat] //経度、緯度の順
      }
    };
  });
  dispach(setClustersAction(clusters));
  return;
}

export const setFilteringMaps = async (dispatch, mapsData, filteringWord, loginUserInfo) => {
  try {
    await getClusters(dispatch, mapsData, null, loginUserInfo);
    dispatch(setFilteringMapsAction(mapsData, filteringWord));
  } catch(e) {
    console.dir(e);
  }
}

const listMapsAction = (mapsData) => {
	return {
		type: 'LIST_MAPS',
		payload: {
			mapsData: mapsData,
		}
	}
}

const setSearchPlaceAction = (searchPlace) => {
  return {
    type: "SET_SEARCH_PLACE",
    payload: {
      searchPlace: searchPlace,
    }
  }
}

const setCurrentLocationAction = (currentLocation) => {
  return {
    type: "SET_CURRENT_LOCATION",
    payload: {
      currentLocation: currentLocation,
    },
  }
}

const setStampAction = (mapsData) => {
  return {
    type: "SET_STAMP",
    payload: {
      mapsData: mapsData,
    },
  }
}

const setFilteringMapsAction = (mapsData, filteringWord) => {
  return {
    type: "SET_FILTERING_MAPS",
    payload: {
      mapsData: mapsData,
      filteringWord: filteringWord,
    }
  }
}

const setloginStatusAction = (loginStatus, loginUserInfo) => {
  return {
    type: "LOGIN_STATUS",
    payload: {
      loginStatus: loginStatus,
      loginUserInfo: loginUserInfo,
    }
  }
}

const setClustersAction = (clusters) => {
  return {
    type: "SET_CLUSTERS",
    payload: {
      clusters: clusters,
    }
  }
}

const setRankAction = (myRank, usersNum, myStampedCnt) => {
  return {
    type: "SET_RANK",
    payload: {
      myRank: myRank,
      usersNum: usersNum,
      myStampedCnt: myStampedCnt,
    }
  }
}
