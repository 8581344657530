type PlaceProps = {
  url: string;
  isAdminUser: boolean;
  onClickMarker: () => void;
  name: string;
  lat: number;
  lng: number;
};
const Place = ({ url, isAdminUser, onClickMarker, name }: PlaceProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img
      src={url}
      width={40}
      height={40}
      style={{ borderRadius: 50, border: "solid", borderColor: "hotpink" }}
      alt="地図のプレイス"
      onClick={onClickMarker}
    />
    <div
      style={{
        width: 76,
        maxWidth: 76,
        height: 15,
        fontSize: "100%",
        fontWeight: 700,
        textAlign: "center",
        padding: "1px 2px",
        borderTop: !isAdminUser ? "4px solid #ff6161" : "4px solid #ffcfcf",
        background: "#f4f4f4",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {name}
      </div>
    </div>
  </div>
);

export default Place;
