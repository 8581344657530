type ClusterProps = {
  cluster: {
    properties: {
      point_count: number;
    };
  };
  lat: number;
  lng: number;
};

const MAX_CNT = 99;
const Cluster = ({ cluster }: ClusterProps) => {
  const pointCount = cluster.properties.point_count;
  const cnt = MAX_CNT < pointCount ? "99+" : pointCount;
  return (
    <div
      style={{
        background: "violet",
        opacity: "0.6",
        minWidth: 40,
        minHeight: 40,
        display: "inline-flex",
        borderRadius: "100%",
        transform: "translate(-50%, -50%)",
        alignItems: "center",
        justifyContent: "center                                       ",
      }}
    >
      <div style={{ fontSize: 26, fontWeight: 700 }}>{cnt}</div>
    </div>
  );
};

export default Cluster;
